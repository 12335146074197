<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!--<CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />-->
      <div class="c-avatar c-sidebar-brand-full">
          <img
            src="../../public/img/TLIC/exam-manager-6.svg"
            width=auto 
            height="62"
          />
          <!-- <object data="img/TLIC/exam-manager-6.svg" width=auto height="62"> </object> -->
      </div>
      <div class="c-avatar c-sidebar-brand-minimized">
          <img
            src="img/TLIC/online-exam-minimize.png"
            width=auto 
            height="38"
          />
      </div>
      <!--<CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      />-->
    </CSidebarBrand>

    <CRenderFunction v-if="!isAdmin" flat :content-to-render="$options.nav"/>
    <CRenderFunction v-if="isAdmin" flat :content-to-render="$options.navAdmin"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navAdmin from './_navAdmin'
import Api from "@/services/apiServices"

export default {
  name: 'TheSidebar',
  nav,
  navAdmin,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },  
  data () {
    return {
      isAdmin:false
    }
  },
  created() {
    let userRole = localStorage.getItem('Admin')
    switch (userRole) {
      case "super-admin":
        this.isAdmin = true
        break
      case "faculty-admin":
        this.isAdmin = true
        break
      default:
        break
    }
  },
  methods: {
    async checkUserRole(email){
      console.log("Hi")
      let eventList = await Api.getEventListByOwner(email)
      if(eventList){
        this.eventList = eventList
        console.log("eventListByOwner(API):",eventList)
      }
    },
  }
}
</script>
