export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/',
            icon: 'cilLaptop',
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['Events']
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Event lists',
            to: '/recording',
            icon: 'cil-list',
        },
    ]
}]