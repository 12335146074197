<template>
  <div class="c-app">
    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
    <div class="bottom-right-btn text-center">
      <CCollapse :show="showBtn" :duration="400">
        <a target="_blank" href="https://tlic-cmu.gitbook.io/online-exam-manager-documentation/">
          <CButton shape="pill" color="warning" style="height:90px;width:120px">
            <CIcon :height="1000" name="cil-book" /><br />
            คู่มือการใช้งาน&nbsp;&nbsp;
          </CButton> </a
        ><br /><br />

        <!-- <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=3_GBz1neKUyR2qLf0EqnUczqxTXCh8JInYUq-w4ZrfhUM045MEhSTFk3UlRMWkVKVFNZMVlNVUpFRS4u"> -->
        <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=3_GBz1neKUyR2qLf0EqnUWOiN2_D0uVLjPI5UBiUP-NUMkJQTTlOMEgwMTJQUkRHWFpGTEFNWUxDQS4u">
          <CButton shape="pill" color="zoomblue" style="height:90px;width:120px">
            <CIcon :height="1000" name="cil-comment-square" /><br />
            แสดงความคิดเห็น
          </CButton> </a
        ><br /><br />

        <a target="_blank" href="https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1">
          <CButton shape="pill" color="success" style="height:90px;width:120px">
            <CIcon :height="1000" name="cil-comment-bubble" /><br />
            ขอความช่วยเหลือ
          </CButton> </a
        ><br /><br />
      </CCollapse>
      <div class="text-center">
        <CButton color="success" @click="hideShowClick">
          <!-- <CIcon :height="1000" name="cil-comment-square"/> -->
          <div v-if="showBtn">
            ซ่อน
            <!-- <br><CIcon :height="1000" name="cil-caret-bottom"/> -->
          </div>
          <div v-if="!showBtn">
            <!-- <CIcon :height="1000" name="cil-caret-top"/><br> -->
            ขอความช่วยเหลือ <i class="far fa-question-circle"></i>
          </div>
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar"
import TheHeader from "./TheHeader"
import TheFooter from "./TheFooter"

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      showBtn: localStorage.getItem("showfloating") ? localStorage.getItem("showfloating") === "true" : true,
    }
  },
  created() {
    // this.$toast.default(`ประกาศจากผู้พัฒนาระบบ ในวันที่ 15-16 ตุลาคม 2564 ระบบเช็คสถานะ zoom ของนักศึกษาจะไม่สามารถใช้งานได้`, {
    // this.$toast.default(`ประกาศปิดปรับปรุงระบบเช็คสถานะ Zoom ของนักศึกษา ซึ่งจะส่งผลให้ไม่สามารถใช้งานระบบเช็คสถานะ Zoom ได้`, {
    //   position: "top",
    //   dismissible: false,
    //   duration: 0
    // })
  },
  methods: {
    hideShowClick() {
      this.showBtn = !this.showBtn
      localStorage.setItem("showfloating", this.showBtn)
      console.log(localStorage.getItem("showfloating"))
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
